import React, { useMemo } from 'react';
import { ApartmentDto, reversePolygonCoords, Feature, BuildingEntity, findBuildingByIdInComplex } from 'services';
import styles from './MapSection.module.scss';
import { RenderBuildingToMap } from "../../../LayoutPage/pages/ApartmentView/components/RenderBuildingToMap";
import { observer } from "mobx-react";
import { useBuildingOnMapPopup } from "../../../LayoutPage/containers/BuildingOnMapPopup";
import { Complex } from "../../../../services/v1/rc/viewVillage/types";
import { getWindowsFromLayers } from "../../../LayoutPage/utils";

interface MapSectionProps {
  apartmentData: ApartmentDto;
  complexData: Complex;
}

export const MapSection: React.FC<MapSectionProps> = observer(({ complexData, apartmentData }) => {
  const layout = apartmentData?.layouts?.[0];
  const polygon = layout?.polygon ? JSON.parse(layout.polygon) : [];

  const windows: Feature[] | undefined = useMemo(() => {
    const result = getWindowsFromLayers(apartmentData?.layouts);
    return result;
  }, [apartmentData?.layouts]);

  const building: BuildingEntity | undefined = findBuildingByIdInComplex(
    apartmentData?.building_id?.toString(),
  )(complexData);

  const reversedPolygon = useMemo(() => {
    const result = reversePolygonCoords(building?.polygon);
    return result;
  }, [building?.polygon]);

  const { showBuildingOnMapPopup } = useBuildingOnMapPopup({
    windows,
    polygon: reversedPolygon,
  });

  return (
    <section className={styles.sectionBlock}>
      <h2 className={styles.sectionTitle}>Расположение и вид из окон</h2>
      <div className={styles.content}>
        <RenderBuildingToMap
          hideZoomControl
          className={styles.complexMap}
          polygon={reversedPolygon}
          windows={windows}
        />
      </div>
    </section>
  );
});

// // import React from 'react';
// // import mapImg from '../../images/map.png';
// // import styles from './MapSection.module.scss';
// //
// // export const MapSection: React.FC = () => {
// //   return (
// //     <section className={styles.sectionBlock}>
// //       <h2 className={styles.sectionTitle}>Расположение и вид из окон</h2>
// //       <div className={styles.sectionImg}>
// //         <img src={mapImg} alt="Расположение и вид из окон" />
// //       </div>
// //     </section>
// //   );
// // };
//
// import React, { useState, useEffect } from 'react';
// import styles from './MapSection.module.scss';
// import { BaseMapContainer, FloorPlanOverlay } from 'components/atoms';
// import { Complex } from '../../../../services';
// // Предполагается, что NearbyObjects уже реализован
// import mapImg from '../../images/map.png';
// import {NearbyObjects} from "../../../ComplexPage/components/NearbyObjects";
//
// interface MapSectionProps {
//   complexData: Complex;
// }
//
// export const MapSection: React.FC<MapSectionProps> = ({ complexData }) => {
//
//   const formattedPolygon: Array<[number, number]> =
//     complexData?.polygon
//       ? complexData?.polygon?.map((coords: [number, number]) => coords?.reverse())
//       : [];
//
//   return (
//     <section className={styles.sectionBlock}>
//       <h2 className={styles.sectionTitle}>Расположение и вид из окон</h2>
//       {/* Содержимое вкладок */}
//       <div className={styles.content}>
//         {complexData?.buildings && (
//           <NearbyObjects
//             description="Поблизости"
//             buildings={complexData?.buildings}
//             polygon={formattedPolygon}
//           />
//         )}
//       </div>
//     </section>
//   );
// };
//




