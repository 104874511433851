import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import { appRoutesService } from 'services';
import eselloLogo from 'assets/eselloNewLogo.svg';
import searchColorIcon from 'assets/searchColorIcon.svg';
import burgerIcon from 'assets/burgerIcon.svg';

import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Icon } from 'components/atoms/Icon';
import styles from './styles.module.css';

type Props = {
  onOpenSearch?: () => void;
  onOpenMenu?: () => void;
};

export const NavbarMobileRender = ({ onOpenSearch, onOpenMenu }: Props) => (
  <div className={styles.mobileWrapper}>
    <header className={styles.content}>
      <ButtonBase
        onClick={onOpenSearch}
        color="transparent"
        icon={<Icon size={24} src={searchColorIcon} />}
      />
      <Link to={appRoutesService.getRoute('main')} className={styles.logo}>
        <img src={eselloLogo} alt="site logo" />
      </Link>
      <ButtonBase
        onClick={onOpenMenu}
        color="transparent"
        icon={<Icon size={24} src={burgerIcon} />}
      />
    </header>
  </div>
);

export const NavbarMobile = observer(NavbarMobileRender);
