// import React, { useState } from 'react';
// import { observer } from "mobx-react";
// import {Loader, Text} from 'components/atoms';
// import { Icon } from 'components/atoms/Icon';
// import closeIcon from 'assets/close.svg';
// import { ButtonBase } from '../../../LayoutPage/components/ButtonBase';
// import { ApartmentDto, authTokenStorageService, Complex } from "../../../../services";
// import { API_ROUTES } from "../../../../services/constants";
// import styles from './PdfSettingsPopup.module.scss';
//
// type PdfSettings = {
//   objectName: boolean | undefined;
//   developerName: boolean | undefined;
//   objectAddress: boolean | undefined;
//   layout: boolean | undefined;
//   floorPlan: boolean | undefined;
//   badges: boolean | undefined;
//   description: boolean | undefined;
//   advantages: boolean | undefined;
//   photos: boolean | undefined;
//   constructionProgress: boolean | undefined;
//   locationAndView: boolean | undefined;
//   price: boolean | undefined;
//   agentContact: boolean | undefined;
// };
//
// type Props = {
//   onClose: () => void;
//   complexData: Complex;
//   apartmentData: ApartmentDto | null;
// };
//
// export const PdfSettingsPopup = observer(({ onClose, complexData, apartmentData }: Props) => {
//   const token = authTokenStorageService?.getToken();
//   const [isLoading, setIsLoading] = useState(false);
//   const constructionAlbums = complexData?.albums?.filter(
//     (album) => album.name === "Ход строительства"
//   );
//
//   const [settings, setSettings] = useState<PdfSettings>({
//     objectName: Boolean(complexData?.title),
//     developerName: Boolean(complexData?.developer),
//     objectAddress: Boolean(complexData?.address),
//     badges: Boolean(apartmentData?.catalogs?.apartment_status),
//     description: Boolean(complexData?.presentation),
//     advantages: Boolean(complexData?.catalogs?.residential_complex_advantages),
//     photos: Boolean(complexData?.presentation),
//     constructionProgress: Boolean(constructionAlbums && constructionAlbums?.length > 0),
//     locationAndView: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.polygon),
//     price: Boolean(apartmentData?.price),
//     layout: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.photo),
//     floorPlan: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.floor_photo),
//     agentContact: Boolean(token),
//   });
//
//   const toggleSetting = (key: keyof PdfSettings) => {
//     setSettings((prev) => ({ ...prev, [key]: !prev[key] }));
//   };
//
//   const onReset = () => {
//     setSettings({
//       objectName: Boolean(complexData?.title),
//       developerName: Boolean(complexData?.developer),
//       objectAddress: Boolean(complexData?.address),
//       badges: Boolean(apartmentData?.catalogs?.apartment_status),
//       description: Boolean(complexData?.presentation),
//       advantages: Boolean(complexData?.catalogs?.residential_complex_advantages),
//       photos: Boolean(complexData?.presentation),
//       constructionProgress: Boolean(constructionAlbums && constructionAlbums?.length > 0),
//       locationAndView: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.polygon),
//       price: Boolean(apartmentData?.price),
//       layout: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.photo),
//       floorPlan: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.floor_photo),
//       agentContact: Boolean(token),
//     });
//     onClose();
//   };
//
//   const onApply = async () => {
//     setIsLoading(true);
//     // Формируем query string из настроек
//     const queryParams = new URLSearchParams();
//
//     Object.entries(settings).forEach(([key, value]) => {
//       const val = value ? 'true' : 'false';
//       queryParams.append(key, val);
//     });
//
//     const apartmentId = apartmentData?.id;
//     if (!apartmentId) {
//       console.error('No apartmentId found');
//       setIsLoading(false);
//       return;
//     }
//
//     const url = `${process.env.REACT_APP_API_V1}${API_ROUTES.pdf}/${apartmentId}/pdf?${queryParams.toString()}`;
//
//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: token ? { 'X-Auth-Token': `${token?.token}` } : {},
//       });
//
//       if (!response.ok) {
//         console.error('Failed to fetch PDF:', response.statusText);
//         setIsLoading(false);
//         return;
//       }
//
//       const data = await response.json();
//       console.log('data', data);
//
//       const pdfUrl = data.url;
//       console.log('PDF URL:', pdfUrl);
//
//       window.open(pdfUrl, '_blank');
//       onClose();
//     } catch (error) {
//       console.error('Error fetching PDF:', error);
//     } finally {
//       setIsLoading(false);
//     }
//   };
//
//   return (
//     <div className={styles.wrapper}>
//       <div className={styles.header}>
//         <Text size="XL" weight="700" className={styles.title}>Настройка PDF</Text>
//         <ButtonBase
//           onClick={onClose}
//           icon={<Icon src={closeIcon} className={styles.closeIcon}/>}
//           className={styles.textClassName}
//         />
//       </div>
//       <Text size="M" className={styles.subHeader}>
//         Отрегулируйте информацию видимую клиенту
//       </Text>
//
//       <div className={styles.columns}>
//         <div className={styles.column}>
//           <label
//             className={`${styles.switchLabel} ${!complexData?.title ? styles.disabled : ''}`}
//             style={{color: !complexData?.title ? '#999' : 'inherit'}}
//           >
//             <span>Название объекта</span>
//             <input
//               type="checkbox"
//               checked={settings?.objectName}
//               onChange={() => toggleSetting('objectName')}
//               className={styles.switch}
//               disabled={!complexData?.title}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${!complexData?.developer ? styles.disabled : ''}`}
//             style={{color: !complexData?.developer ? '#999' : 'inherit'}}
//           >
//             <span>Название застройщика</span>
//             <input
//               type="checkbox"
//               checked={settings.developerName}
//               onChange={() => toggleSetting('developerName')}
//               className={styles.switch}
//               disabled={!complexData?.developer}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${!complexData?.address ? styles.disabled : ''}`}
//             style={{color: !complexData?.address ? '#999' : 'inherit'}}
//           >
//             <span>Адрес объекта</span>
//             <input
//               type="checkbox"
//               checked={settings.objectAddress}
//               onChange={() => toggleSetting('objectAddress')}
//               className={styles.switch}
//               disabled={!complexData?.address}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${!apartmentData?.catalogs?.apartment_status ? styles.disabled : ''}`}
//             style={{color: !apartmentData?.catalogs?.apartment_status ? '#999' : 'inherit'}}
//           >
//             <span>Бэйджи</span>
//             <input
//               type="checkbox"
//               checked={settings.badges}
//               onChange={() => toggleSetting('badges')}
//               className={styles.switch}
//               disabled={!apartmentData?.catalogs?.apartment_status}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${!complexData?.presentation ? styles.disabled : ''}`}
//             style={{color: !complexData?.presentation ? '#999' : 'inherit'}}
//           >
//             <span>Описание</span>
//             <input
//               type="checkbox"
//               checked={settings.description}
//               onChange={() => toggleSetting('description')}
//               className={styles.switch}
//               disabled={!complexData?.presentation}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${!complexData?.catalogs?.residential_complex_advantages ? styles.disabled : ''}`}
//             style={{color: !complexData?.catalogs?.residential_complex_advantages ? '#999' : 'inherit'}}
//           >
//             <span>Преимущества</span>
//             <input
//               type="checkbox"
//               checked={settings.advantages}
//               onChange={() => toggleSetting('advantages')}
//               className={styles.switch}
//               disabled={!complexData?.catalogs?.residential_complex_advantages}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${!complexData?.presentation ? styles.disabled : ''}`}
//             style={{color: !complexData?.presentation ? '#999' : 'inherit'}}
//           >
//             <span>Фотографии</span>
//             <input
//               type="checkbox"
//               checked={settings.photos}
//               onChange={() => toggleSetting('photos')}
//               className={styles.switch}
//               disabled={!complexData?.presentation}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${(!constructionAlbums || constructionAlbums.length === 0) ? styles.disabled : ''}`}
//             style={{color: (!constructionAlbums || constructionAlbums.length === 0) ? '#999' : 'inherit'}}
//           >
//             <span>Ход строительства</span>
//             <input
//               type="checkbox"
//               checked={settings.constructionProgress}
//               onChange={() => toggleSetting('constructionProgress')}
//               className={styles.switch}
//               disabled={!constructionAlbums || constructionAlbums.length === 0}
//             />
//           </label>
//         </div>
//         <div className={styles.column}>
//           <label
//             className={`${styles.switchLabel} ${!(apartmentData?.layouts && apartmentData?.layouts[0]?.photo) ? styles.disabled : ''}`}
//             style={{color: !(apartmentData?.layouts && apartmentData?.layouts[0]?.photo) ? '#999' : 'inherit'}}
//           >
//             <span>Планировка</span>
//             <input
//               type="checkbox"
//               checked={settings.layout}
//               onChange={() => toggleSetting('layout')}
//               className={styles.switch}
//               disabled={!(apartmentData?.layouts && apartmentData?.layouts[0]?.photo)}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${!(apartmentData?.layouts && apartmentData?.layouts[0]?.floor_photo) ? styles.disabled : ''}`}
//             style={{color: !(apartmentData?.layouts && apartmentData?.layouts[0]?.floor_photo) ? '#999' : 'inherit'}}
//           >
//             <span>План этажа</span>
//             <input
//               type="checkbox"
//               checked={settings.floorPlan}
//               onChange={() => toggleSetting('floorPlan')}
//               className={styles.switch}
//               disabled={!(apartmentData?.layouts && apartmentData?.layouts[0]?.floor_photo)}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${!(apartmentData?.layouts && apartmentData?.layouts[0]?.polygon) ? styles.disabled : ''}`}
//             style={{color: !(apartmentData?.layouts && apartmentData?.layouts[0]?.polygon) ? '#999' : 'inherit'}}
//           >
//             <span>Расположение и вид из окна</span>
//             <input
//               type="checkbox"
//               checked={settings.locationAndView}
//               onChange={() => toggleSetting('locationAndView')}
//               className={styles.switch}
//               disabled={!(apartmentData?.layouts && apartmentData?.layouts[0]?.polygon)}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${!apartmentData?.price ? styles.disabled : ''}`}
//             style={{color: !apartmentData?.price ? '#999' : 'inherit'}}
//           >
//             <span>Стоимость объекта</span>
//             <input
//               type="checkbox"
//               checked={settings.price}
//               onChange={() => toggleSetting('price')}
//               className={styles.switch}
//               disabled={!apartmentData?.price}
//             />
//           </label>
//           <label
//             className={`${styles.switchLabel} ${!token ? styles.disabled : ''}`}
//             style={{color: !token ? '#999' : 'inherit'}}
//           >
//             <span>Контакты агента</span>
//             <input
//               type="checkbox"
//               checked={settings.agentContact}
//               onChange={() => toggleSetting('agentContact')}
//               className={styles.switch}
//               disabled={!token}
//             />
//           </label>
//         </div>
//       </div>
//
//
//       <div className={styles.actions}>
//         <button onClick={onReset} className={styles.resetButton}>
//           Отмена
//         </button>
//         <button onClick={onApply} className={styles.applyButton}>
//           Создать PDF {isLoading && <Loader size="normal" color="primary" />}
//         </button>
//       </div>
//     </div>
//   );
// });

import React, { useState } from 'react';
import { observer } from "mobx-react";
import {Loader, Text} from 'components/atoms';
import { Icon } from 'components/atoms/Icon';
import closeIcon from 'assets/close.svg';
import { ButtonBase } from '../../../LayoutPage/components/ButtonBase';
import { ApartmentDto, authTokenStorageService, Complex } from "../../../../services";
import { API_ROUTES } from "../../../../services/constants";
import styles from './PdfSettingsPopup.module.scss';

type PdfSettings = {
  objectName: boolean | undefined;
  developerName: boolean | undefined;
  objectAddress: boolean | undefined;
  layout: boolean | undefined;
  floorPlan: boolean | undefined;
  badges: boolean | undefined;
  description: boolean | undefined;
  advantages: boolean | undefined;
  photos: boolean | undefined;
  constructionProgress: boolean | undefined;
  locationAndView: boolean | undefined;
  price: boolean | undefined;
  agentContact: boolean | undefined;
};

type Props = {
  onClose: () => void;
  complexData: Complex;
  apartmentData: ApartmentDto | null;
};

export const PdfSettingsPopup = observer(({ onClose, complexData, apartmentData }: Props) => {
  const token = authTokenStorageService?.getToken();
  const [isLoading, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const constructionAlbums = complexData?.albums?.filter(
    (album) => album.name === "Ход строительства"
  );

  const [settings, setSettings] = useState<PdfSettings>({
    objectName: Boolean(complexData?.title),
    developerName: Boolean(complexData?.developer),
    objectAddress: Boolean(complexData?.address),
    badges: Boolean(apartmentData?.catalogs?.apartment_status),
    description: Boolean(complexData?.presentation),
    advantages: Boolean(complexData?.catalogs?.residential_complex_advantages),
    photos: Boolean(complexData?.presentation),
    constructionProgress: Boolean(constructionAlbums && constructionAlbums?.length > 0),
    locationAndView: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.polygon),
    price: Boolean(apartmentData?.price),
    layout: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.photo),
    floorPlan: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.floor_photo),
    agentContact: Boolean(token),
  });

  const toggleSetting = (key: keyof PdfSettings) => {
    setSettings((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const onReset = () => {
    setSettings({
      objectName: Boolean(complexData?.title),
      developerName: Boolean(complexData?.developer),
      objectAddress: Boolean(complexData?.address),
      badges: Boolean(apartmentData?.catalogs?.apartment_status),
      description: Boolean(complexData?.presentation),
      advantages: Boolean(complexData?.catalogs?.residential_complex_advantages),
      photos: Boolean(complexData?.presentation),
      constructionProgress: Boolean(constructionAlbums && constructionAlbums?.length > 0),
      locationAndView: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.polygon),
      price: Boolean(apartmentData?.price),
      layout: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.photo),
      floorPlan: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.floor_photo),
      agentContact: Boolean(token),
    });
    onClose();
  };

  const onCreatePdf = async () => {
    setIsLoading(true);
    setPdfUrl(null);

    const queryParams = new URLSearchParams();

    Object.entries(settings).forEach(([key, value]) => {
      const val = value ? 'true' : 'false';
      queryParams.append(key, val);
    });

    const apartmentId = apartmentData?.id;
    if (!apartmentId) {
      console.error('No apartmentId found');
      setIsLoading(false);
      return;
    }

    const url = `${process.env.REACT_APP_API_V1}${API_ROUTES.pdf}/${apartmentId}/pdf?${queryParams.toString()}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: token ? { 'X-Auth-Token': `${token?.token}` } : {},
      });

      if (!response.ok) {
        console.error('Failed to fetch PDF:', response.statusText);
        setIsLoading(false);
        return;
      }

      const data = await response.json();
      const pdfUrlFromServer = data.url;
      setPdfUrl(pdfUrlFromServer);
      console.log('PDF URL:', pdfUrlFromServer);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onOpenPdf = () => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
      onClose();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Text size="XL" weight="700" className={styles.title}>Настройка PDF</Text>
        <ButtonBase
          onClick={onClose}
          icon={<Icon src={closeIcon} className={styles.closeIcon}/>}
          className={styles.textClassName}
        />
      </div>
      <Text size="M" className={styles.subHeader}>
        Отрегулируйте информацию видимую клиенту
      </Text>

      <div className={styles.columns}>
        <div className={styles.column}>
          <label
            className={`${styles.switchLabel} ${!complexData?.title ? styles.disabled : ''}`}
            style={{color: !complexData?.title ? '#999' : 'inherit'}}
          >
            <span>Название объекта</span>
            <input
              type="checkbox"
              checked={settings?.objectName}
              onChange={() => toggleSetting('objectName')}
              className={styles.switch}
              disabled={!complexData?.title}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!complexData?.developer ? styles.disabled : ''}`}
            style={{color: !complexData?.developer ? '#999' : 'inherit'}}
          >
            <span>Название застройщика</span>
            <input
              type="checkbox"
              checked={settings.developerName}
              onChange={() => toggleSetting('developerName')}
              className={styles.switch}
              disabled={!complexData?.developer}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!complexData?.address ? styles.disabled : ''}`}
            style={{color: !complexData?.address ? '#999' : 'inherit'}}
          >
            <span>Адрес объекта</span>
            <input
              type="checkbox"
              checked={settings.objectAddress}
              onChange={() => toggleSetting('objectAddress')}
              className={styles.switch}
              disabled={!complexData?.address}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!apartmentData?.catalogs?.apartment_status ? styles.disabled : ''}`}
            style={{color: !apartmentData?.catalogs?.apartment_status ? '#999' : 'inherit'}}
          >
            <span>Бэйджи</span>
            <input
              type="checkbox"
              checked={settings.badges}
              onChange={() => toggleSetting('badges')}
              className={styles.switch}
              disabled={!apartmentData?.catalogs?.apartment_status}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!complexData?.presentation ? styles.disabled : ''}`}
            style={{color: !complexData?.presentation ? '#999' : 'inherit'}}
          >
            <span>Описание</span>
            <input
              type="checkbox"
              checked={settings.description}
              onChange={() => toggleSetting('description')}
              className={styles.switch}
              disabled={!complexData?.presentation}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!complexData?.catalogs?.residential_complex_advantages ? styles.disabled : ''}`}
            style={{color: !complexData?.catalogs?.residential_complex_advantages ? '#999' : 'inherit'}}
          >
            <span>Преимущества</span>
            <input
              type="checkbox"
              checked={settings.advantages}
              onChange={() => toggleSetting('advantages')}
              className={styles.switch}
              disabled={!complexData?.catalogs?.residential_complex_advantages}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!complexData?.presentation ? styles.disabled : ''}`}
            style={{color: !complexData?.presentation ? '#999' : 'inherit'}}
          >
            <span>Фотографии</span>
            <input
              type="checkbox"
              checked={settings.photos}
              onChange={() => toggleSetting('photos')}
              className={styles.switch}
              disabled={!complexData?.presentation}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${(!constructionAlbums || constructionAlbums.length === 0) ? styles.disabled : ''}`}
            style={{color: (!constructionAlbums || constructionAlbums.length === 0) ? '#999' : 'inherit'}}
          >
            <span>Ход строительства</span>
            <input
              type="checkbox"
              checked={settings.constructionProgress}
              onChange={() => toggleSetting('constructionProgress')}
              className={styles.switch}
              disabled={!constructionAlbums || constructionAlbums.length === 0}
            />
          </label>
        </div>
        <div className={styles.column}>
          <label
            className={`${styles.switchLabel} ${!(apartmentData?.layouts && apartmentData?.layouts[0]?.photo) ? styles.disabled : ''}`}
            style={{color: !(apartmentData?.layouts && apartmentData?.layouts[0]?.photo) ? '#999' : 'inherit'}}
          >
            <span>Планировка</span>
            <input
              type="checkbox"
              checked={settings.layout}
              onChange={() => toggleSetting('layout')}
              className={styles.switch}
              disabled={!(apartmentData?.layouts && apartmentData?.layouts[0]?.photo)}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!(apartmentData?.layouts && apartmentData?.layouts[0]?.floor_photo) ? styles.disabled : ''}`}
            style={{color: !(apartmentData?.layouts && apartmentData?.layouts[0]?.floor_photo) ? '#999' : 'inherit'}}
          >
            <span>План этажа</span>
            <input
              type="checkbox"
              checked={settings.floorPlan}
              onChange={() => toggleSetting('floorPlan')}
              className={styles.switch}
              disabled={!(apartmentData?.layouts && apartmentData?.layouts[0]?.floor_photo)}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!(apartmentData?.layouts && apartmentData?.layouts[0]?.polygon) ? styles.disabled : ''}`}
            style={{color: !(apartmentData?.layouts && apartmentData?.layouts[0]?.polygon) ? '#999' : 'inherit'}}
          >
            <span>Расположение и вид из окна</span>
            <input
              type="checkbox"
              checked={settings.locationAndView}
              onChange={() => toggleSetting('locationAndView')}
              className={styles.switch}
              disabled={!(apartmentData?.layouts && apartmentData?.layouts[0]?.polygon)}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!apartmentData?.price ? styles.disabled : ''}`}
            style={{color: !apartmentData?.price ? '#999' : 'inherit'}}
          >
            <span>Стоимость объекта</span>
            <input
              type="checkbox"
              checked={settings.price}
              onChange={() => toggleSetting('price')}
              className={styles.switch}
              disabled={!apartmentData?.price}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!token ? styles.disabled : ''}`}
            style={{color: !token ? '#999' : 'inherit'}}
          >
            <span>Контакты агента</span>
            <input
              type="checkbox"
              checked={settings.agentContact}
              onChange={() => toggleSetting('agentContact')}
              className={styles.switch}
              disabled={!token}
            />
          </label>
        </div>
      </div>


      <div className={styles.actions}>
        <button onClick={onReset} className={styles.resetButton}>
          Отмена
        </button>

        {pdfUrl ? (
          <button onClick={onOpenPdf} className={styles.applyButton}>
            Открыть PDF файл
          </button>
        ) : (
          <button onClick={onCreatePdf} className={styles.applyButton} disabled={isLoading}>
            Создать PDF {isLoading && <Loader size="normal" color="primary" />}
          </button>
        )}
      </div>
    </div>
  );
});

