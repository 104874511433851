import React, {useEffect} from 'react';
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import pdfIcon from 'assets/pdfIcon.svg';
import { Icon } from "../../atoms";
import { usePdfSettingsPopup } from "../../../pages/PdfPage/components/PdfSettingsPopup/hooks/usePdfSettingsPopup";
import { apartmentsService, Params } from "../../../services";
import { complexItemStore } from "../../../stores/ComplexItemStore/store";
import { feedbackStore } from "../../../stores/FeedbackStore/FeedbackStore";
import { useInitApartmentSidebar } from "../../../pages/LayoutPage/hooks/useInitApartmentSidebar";
import { rootApartmentStore } from "../../../stores/Apartment/RootApartmentStore";
import styles from './styles.module.scss';

type Props = {
  showLabels?: boolean;
  textClassName?: string;
};

export const PdfButtonRender = ({
  textClassName,
}: Props) => {
  const { complexId } = useParams<Pick<Params, 'complexId'>>();
  const { showPdfSettingsPopup } = usePdfSettingsPopup();
  const {
    isVisible,
    target: complexData,
    toggleVisibility,
    fetchComplex,
    clearTarget,
  } = complexItemStore;

  useEffect(() => {
    if (!complexData || complexData?.id !== Number(complexId)) {
      fetchComplex(Number(complexId));
      toggleVisibility(true);
      feedbackStore.setInitialValues({
        type: 'complex',
        recordId: complexId,
      });
    }
  }, [complexId, fetchComplex, toggleVisibility, complexData]);

  useInitApartmentSidebar({ apartmentsService });
  const { apartmentUIStore } = rootApartmentStore;


  const onClick = () => {
    showPdfSettingsPopup(complexData, apartmentUIStore?.activeApartment || null);
  };

  return (
    <ButtonBase
      onClick={onClick}
      icon={<Icon src={pdfIcon} />}
      textClassName={textClassName}
      className={styles.textClassName}
    >
    </ButtonBase>
  );
};

export const PdfButton = observer(PdfButtonRender);
