import React from 'react';
import { ApartmentDto } from "../../../../services";
import { DictionaryView } from "../../../../components/atoms";
import styles from './ApartmentDetails.module.scss';

interface ApartmentDetailsProps {
  images: string[];
  apartmentData: ApartmentDto;
  complexData: any;
  objectNameQP?: boolean;
  objectAddressQP?: boolean;
  badgesQP?: boolean;
  priceQP?: boolean;
  layoutQP?: boolean;
}

export const ApartmentDetails: React.FC<ApartmentDetailsProps> = ({
  images,
  apartmentData,
  complexData,
  objectNameQP,
  objectAddressQP,
  badgesQP,
  priceQP,
  layoutQP
}) => {
  const hasMultipleImages = images?.length > 1;
  const roomType = apartmentData?.catalogs?.rooms;
  const apartmentType = apartmentData?.catalogs?.apartment_type_room;
  const totalArea = apartmentData?.square;
  const statusCodes = apartmentData?.catalogs?.apartment_status;
  const district = complexData?.district;
  const complexTitle = complexData?.title;
  const complexAddress = complexData?.address;
  const building = apartmentData?.building_title;
  const pricePerM2 = apartmentData?.price_m2;
  const totalPrice = apartmentData?.price;
  const completionDate = apartmentData?.building_completion_construction_at
    ? new Date(apartmentData?.building_completion_construction_at).toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
    : 'Дата сдачи не указана';

  return (
    <section className={styles.apartmentDetails}>
      <div className={styles.content}>
        <div className={styles.description}>
          {
            statusCodes && badgesQP && (
              <div className={styles.status}>
                {/*<span className={`${styles.statusBadge} ${styles.green}`}>ПОСТРОЕН</span>*/}
                <span className={`${styles.statusBadge} ${styles.green}`}>
                  <DictionaryView dictKey="apartment_status" dictItemId={statusCodes}/>
                </span>
                {/*<span className={`${styles.statusBadge} ${styles.green}`}>В ПРОДАЖЕ</span>*/}
                {/*<span className={`${styles.statusBadge} ${styles.red}`}>МАТЕРИНСКИЙ КАПИТАЛ</span>*/}
                {/*<span className={`${styles.statusBadge} ${styles.greenLight}`}>ВОЕННАЯ ИПОТЕКА</span>*/}
                {/*<span className={`${styles.statusBadge} ${styles.blue}`}>РАССРОЧКА 0%</span>*/}
              </div>
            )
          }
          <h2>
            <span>{roomType ? <DictionaryView dictKey="rooms" dictItemId={roomType}/> : ''}</span>
            <span>{' '}</span>
            <span>{apartmentType ?
              <DictionaryView dictKey="apartment_type_room" dictItemId={apartmentType}/> : ''}</span>
            <span>{' '}</span>
            <span>{totalArea ? `${totalArea} м²` : ''}</span>
          </h2>
          {
            district && <p>{district}</p>
          }
          <p>
            {objectNameQP &&
              <span>в <span>{complexTitle}</span>,</span>} {building && `${building},`} сдан {completionDate}
          </p>
          {
            complexAddress && objectAddressQP && <p>{complexAddress}</p>
          }
          {
            pricePerM2 && priceQP && (
              <p className={styles.pricePerM}>
                {`${pricePerM2.toLocaleString('ru-RU')} ₽/м²`}
              </p>
            )
          }
          {
            totalPrice && priceQP && (
              <p className={styles.totalPrice}>
                {`${totalPrice.toLocaleString('ru-RU')} ₽`}
              </p>
            )
          }
        </div>

        {!hasMultipleImages && layoutQP ? (
          <div className={styles.singleImageContainer}>
            <img src={images[0]} alt="Планировка квартиры" className={styles.apartmentPlan} />
          </div>
        ) : null}
      </div>

      {hasMultipleImages && layoutQP ? (
        <div className={styles.imagesContainer}>
          {images.map((image, index) => (
            <div className={styles.plan} key={index}>
              <img src={image} alt={`Планировка квартиры ${index + 1}`} className={styles.apartmentPlan} />
            </div>
          ))}
        </div>
      ) : null}
    </section>
  );
};
