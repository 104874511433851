import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Section, getAllColsSizes, getMaxRows } from 'services';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import styles from './styles.module.css';
import { MayBe } from '../../../../../../types';
import { scrollBarStore } from '../../ScrollBarStore';
import { gridApartmentsStylesStore } from '../../stores/GridApartmentsStylesStore';
import { rootApartmentStore } from '../../../../../../stores/Apartment/RootApartmentStore';

type Props = {
  children: ReactNode;
  sections: MayBe<Section[]>;
};

const GridContainerTestIDs = {
  wrapper: 'GridContainer_wrapper',
};

export const GridContainerRender = ({ children, sections }: Props) => {
  const isLarge = useMediaQuery('(min-width: 768px)');
  const { apartmentUIStore } = rootApartmentStore;
  const scrollBarRef = useRef<HTMLDivElement | null>(null);
  const offsetRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isLarge) {
      gridApartmentsStylesStore.setLargeValues();
    } else {
      gridApartmentsStylesStore.setSmallValues();
    }
  }, [isLarge]);

  const {
    FLOOR_SIDEBAR_WIDTH,
    APARTMENT_VIEW_WIDTH,
    SECTIONS_COLUMN_HEIGHT,
    SECTIONS_TITLE_HEIGHT,
  } = gridApartmentsStylesStore;

  const maxRows: ReturnType<typeof getMaxRows> = useMemo(
    () => getMaxRows(sections),
    [sections],
  );
  const headersRowsCount = 2;

  /**
   * rowsCount = (count header's rows) + (max rows in matrix)
   * */
  const rowsCount: number = headersRowsCount + (maxRows || 0);

  const colsSizes = useMemo(() => getAllColsSizes(sections), [sections]);

  const columns = useMemo(
    () =>
      `${FLOOR_SIDEBAR_WIDTH}px repeat( ${colsSizes.length}, minmax(min-content, max-content))`,
    [FLOOR_SIDEBAR_WIDTH, colsSizes],
  );

  const getTemplateRows = useCallback(
    (rowsCount: number): string =>
      `${SECTIONS_TITLE_HEIGHT}px ${SECTIONS_COLUMN_HEIGHT}px repeat(${rowsCount}, minmax(min-content, max-content))`,
    [],
  );

  const [initialWidth, setInitialWidth] = useState(APARTMENT_VIEW_WIDTH);

  const offsetStyle = useMemo(() => {
    if (!isLarge) return null;

    return {
      width: apartmentUIStore.activeApartment ? `${initialWidth}px` : '0px',
      transition: apartmentUIStore.activeApartment ? 'none' : 'width 0.3s ease',
    };
  }, [isLarge, apartmentUIStore.activeApartment, initialWidth]);

    useEffect(() => {
    if (apartmentUIStore.activeApartment) {
      setInitialWidth(APARTMENT_VIEW_WIDTH);
    }
  }, [apartmentUIStore.activeApartment]);


  // Привязываем ScrollBarStore к элементу и добавляем проверку конца скролла
  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarStore.setSimpleBarInstance(scrollBarRef.current, offsetRef.current);
    }

    const handleScroll = () => {
      scrollBarStore.onScrollHandler();
    };

    scrollBarRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      scrollBarRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [apartmentUIStore.activeApartment]);

  useEffect(() => {
    if (scrollBarRef.current) {
      const currentScrollLeft = scrollBarRef.current.scrollLeft;

      const handleCloseDrawer = () => {
        requestAnimationFrame(() => {
          if (scrollBarRef.current) {
            scrollBarRef.current.scrollLeft = currentScrollLeft;
          }
        });
      };

      if (apartmentUIStore.activeApartment === undefined) {
        handleCloseDrawer();
      }
    }
  }, [apartmentUIStore.activeApartment]);


  return (
    <div
      {...dataTestIdToProps(GridContainerTestIDs.wrapper)}
      ref={scrollBarRef}
      className={styles.scrollWrapper}
    >
      <div className={styles.scroll}>
        <div
          className={styles.wrapper}
          style={{
            gridTemplateColumns: columns,
            gridTemplateRows: getTemplateRows(rowsCount),
          }}
        >
          {children}
           {/**
           Это офсет для возможности скролить к элементам в самом конце сетки
           без него, активные элементы сетки могут быть спрятаны под сайдбаром
           */}
          {isLarge
            && (
              <div
                ref={offsetRef}
                style={{
                  ...offsetStyle,
                  gridRowStart: 3,
                  gridColumnStart: colsSizes.length + 2,
                  gridRowEnd: rowsCount + 1,
                  gridColumnEnd: colsSizes.length + 3,
                }}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export const GridContainer = observer(GridContainerRender);
