import React from 'react';
import styles from './PhotosSection.module.scss';

interface PhotosSectionProps {
  title: string;
  date?: React.ReactNode;
  photos: Array<string[]>;
}

export const PhotosSection: React.FC<PhotosSectionProps> = ({ title, date, photos }) => {
  return (
    <section className={styles.photosSection}>
      <h2 className={styles.sectionTitle}>
        {title} {date && <span>{date}</span>}
      </h2>
      <div className={styles.photos}>
        {photos.map((photoGroup, index) => (
          <div
            key={index}
            className={
              photoGroup.length === 1 ? styles.photoRow : styles.twoPhotosRow
            }
          >
            {photoGroup.map((photo, idx) => (
              <div className={styles.photoContainer} key={idx}>
                <img src={photo} alt={`Photo ${idx + 1}`} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};
