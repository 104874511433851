import React from 'react';
import { ErrorBoundary } from 'components/atoms';
import { rootApartmentStore } from '../../../../stores/Apartment/RootApartmentStore';
import {
  ShowApartmentOnFloorLayout
} from "../../../LayoutPage/pages/ApartmentView/components/ShowApartmentOnFloorLayout";
import styles from './PlanSection.module.scss';

interface PlanSectionProps {
  className?: string;
}

export const PlanSection: React.FC<PlanSectionProps> = ({ className }) => {
  const { apartmentUIStore } = rootApartmentStore;

  return (
    <section className={`${styles.planSection} ${className}`}>
      <h2 className={styles.sectionTitle}>План этажа</h2>
      <div className={styles.floorPlanWrapper}>
        <ErrorBoundary>
          <ShowApartmentOnFloorLayout
            hideZoomControl
            apartment={apartmentUIStore?.activeApartment}
            className={styles.planFloor}
            isPdf={true}
          />
        </ErrorBoundary>
      </div>
    </section>
  );
};
