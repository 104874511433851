import React, { useEffect, useState, FC } from 'react';
import qs from 'qs';
import { observer } from "mobx-react";
import { AttemptResult, userProfileService } from '../../../../services';
import avatarIcon from 'assets/noUser.svg';
import styles from './BusinessCard.module.scss';

export const BusinessCard: FC = observer(() => {
  const [userData, setUserData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  // Извлечение токена из URL
  useEffect(() => {
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const token = queryParams.token as string;

    if (token) {
      fetchUserProfile(token);
    } else {
      setError('Токен не найден в URL');
    }
  }, []);

  const fetchUserProfile = async (token: string) => {
    try {
      const [fetchError, userInfo]: AttemptResult<any> = await userProfileService.getUserProfilePdf(token);

      if (fetchError) {
        setError('Не удалось загрузить данные пользователя');
      } else {
        setUserData(userInfo?.user);
      }
    } catch (e) {
      setError('Произошла ошибка при загрузке данных пользователя');
    }
  };

  const userName = userData?.name && userData?.surname ? `${userData.name} ${userData.surname}` : null;
  const userRole = userData?.role ? userData?.role === 'realtor' ? 'Специалист по недвижимости' : userData?.role : null;
  const userExperience = userData?.description || null;
  const userPhone = userData?.phone ? `+${userData.phone}` : null;
  const userEmail = userData?.email || null;
  const userAvatar = userData?.logo?.url || avatarIcon;
  const companyLogo = userData?.agency?.logo?.url || null;
  const companyName = userData?.agency?.title || null;
  const companyFoundedAt = userData?.agency?.foundedAt
    ? `Работает с ${new Date(userData?.agency?.foundedAt).getFullYear()} года`
    : null;

  return (
    userData ? (
      <section className={styles.businessCard}>
        <div className={styles.profile}>
          <div className={styles.avatar}>
            <img src={userAvatar} alt="Аватар пользователя" className={styles.profilePhoto}/>
          </div>
          <div className={styles.details}>
            {userRole && <p className={styles.title}>{userRole}</p>}
            {userExperience && <p className={styles.description}>{userExperience}</p>}
            {userName && <h2 className={styles.name}>{userName}</h2>}
            {userPhone && <p className={styles.contact}>{userPhone}</p>}
            {userEmail && <p className={styles.contact}>{userEmail}</p>}
          </div>
        </div>

        <div className={styles.company}>
          {companyLogo && (
            <img src={companyLogo} alt="Логотип компании" className={styles.companyLogo}/>
          )}
          <div className={styles.companyDetails}>
            {companyName && <h2 className={styles.companyName}>{companyName}</h2>}
            {companyFoundedAt && <p className={styles.companySlogan}>{companyFoundedAt}</p>}
          </div>
        </div>
      </section>
    ) : (
      <div></div>
    )
  );
});


