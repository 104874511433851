import React, { FC } from 'react';
import checkIcon from '../../images/check-icon.png'
import styles from './AdvantagesList.module.scss';
import {Complex, findDictItemValue} from "../../../../services";
import {infoStore} from "../../../../stores";
import {observer} from "mobx-react";

interface AdvantagesListProps {
  complexData: Complex;
}

export const AdvantagesList: FC<AdvantagesListProps> = observer(({ complexData }) => {
  const advantageNames = findDictItemValue(
    'residential_complex_advantages',
    complexData?.catalogs?.residential_complex_advantages ? complexData?.catalogs?.residential_complex_advantages : [],
  )(infoStore?.dictionaries) || [];

  return (
    <section className={styles.advantages}>
      <h2 className={styles.sectionTitle}>Преимущества</h2>
      <div className={styles.list}>
        {advantageNames?.map((advantage, index) => (
          <div className={styles.item} key={index}>
            <img src={checkIcon} alt="" className={styles.icon} />
            {advantage}
          </div>
        ))}
      </div>
    </section>
  );
});

