import { makeObservable, action, observable } from 'mobx';
import SimpleBar from 'simplebar-react';
import { throttle } from 'lodash';

export class ScrollBarStore {
  simpleBarInstance: SimpleBar | null = null;
  scrollContainer: HTMLElement | null = null;
  scrollTop = 0;
  scrollLeft = 0;
  offsetBox: HTMLElement | null = null;
  isShouldAddOffset = false

  constructor() {
    makeObservable(this, {
      scrollTop: observable,
      scrollLeft: observable,
      scrollTo: action,
      setSimpleBarInstance: action,
      onScrollHandler: action,
      isShouldAddOffset: observable,
    });
  }

  clear = () => {
    this.scrollContainer = null;
    this.simpleBarInstance = null;
    this.scrollTop = 0;
    this.scrollLeft = 0;
  };

  scrollTo = (top: number, left: number) => {
    if (!this.scrollContainer) {
      return;
    }
    const { offsetHeight, offsetWidth } = this.scrollContainer || {};
    if (!offsetHeight || !offsetWidth) {
      return;
    }
    this.scrollContainer?.scrollTo({
      top,
      left,
      behavior: 'smooth',
    });
  };

  checkIfScrolledToEnd = () => {
    if (!this.scrollContainer) return;

    const distanceToEnd = this.scrollContainer.scrollWidth - (this.offsetBox?.scrollWidth ?? 0) - this.scrollContainer.clientWidth - this.scrollContainer.scrollLeft;
    this.isShouldAddOffset = distanceToEnd <= 400;
  };

  onScrollHandler = () => {
    this.scrollTop = this.scrollContainer?.scrollTop || 0;
    this.scrollLeft = this.scrollContainer?.scrollLeft || 0;
    this.checkIfScrolledToEnd()
  };

  onScrollHandlerThrottle = throttle(this.onScrollHandler, 200);

  onScroll = () => {
    this.scrollContainer?.addEventListener(
      'scroll',
      this.onScrollHandlerThrottle,
    );
};

  setSimpleBarInstance = (scrollContainer: HTMLElement, offSetBox: HTMLDivElement | null) => {
    this.scrollContainer = scrollContainer;
    this.offsetBox = offSetBox
    if (!this.scrollContainer) return;

    this.onScroll();
  };
}

export const scrollBarStore = new ScrollBarStore();
