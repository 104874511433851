import React, {FC} from 'react';
import { Complex } from "../../../../services";
import { DictionaryView } from "../../../../components/atoms";
import styles from './DescriptionGrid.module.scss';

interface DescriptionGridProps {
  complexData: Complex;
}

export const DescriptionGrid: FC<DescriptionGridProps> = ({ complexData }) => {
  const material = complexData?.catalogs?.residential_complex_material;
  const complexType = complexData?.catalogs?.residential_complex_type;
  const payType = complexData?.catalogs?.residential_complex_payment_type;
  const finishingType = complexData?.catalogs?.residential_complex_finishing;
  const classType = complexData?.catalogs?.residential_complex_class;
  const houseType = complexData?.catalogs?.residential_complex_house_type;
  const salesStatus = complexData?.catalogs?.residential_complex_sales_status;
  const energyClass = complexData?.catalogs?.residential_complex_energy_class;
  const gas = complexData?.catalogs?.residential_complex_gas;
  const facade = complexData?.catalogs?.residential_complex_facade;
  const contractType = complexData?.catalogs?.residential_complex_contract_type;
  const territory = complexData?.catalogs?.residential_complex_territory;

  return (
    <section className={styles.description}>
      <h2 className={styles.sectionTitle}>Описание</h2>
      <div className={styles.grid}>
        <div className={styles.item}>
          <p className={styles.label}>Материал зданий</p>
          <p className={styles.value}>{material ?
            <DictionaryView dictKey="residential_complex_material" dictItemId={material}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Оплата</p>
          <p className={styles.value}>{payType ?
            <DictionaryView dictKey="residential_complex_payment_type" dictItemId={payType}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Отделка</p>
          <p className={styles.value}>{finishingType ?
            <DictionaryView dictKey="residential_complex_finishing" dictItemId={finishingType}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Тип</p>
          <p className={styles.value}>{complexType ?
            <DictionaryView dictKey="residential_complex_type" dictItemId={complexType}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Класс жк</p>
          <p className={styles.value}>{classType ?
            <DictionaryView dictKey="residential_complex_class" dictItemId={classType}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Тип дома</p>
          <p className={styles.value}>{houseType ?
            <DictionaryView dictKey="residential_complex_house_type" dictItemId={houseType}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Статус продаж</p>
          <p className={styles.value}>{salesStatus ?
            <DictionaryView dictKey="residential_complex_sales_status" dictItemId={salesStatus}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Класс энергоэффективности здания</p>
          <p className={styles.value}>{energyClass ?
            <DictionaryView dictKey="residential_complex_energy_class" dictItemId={energyClass}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Газ</p>
          <p className={styles.value}>{gas ?
            <DictionaryView dictKey="residential_complex_gas" dictItemId={gas}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Фасад</p>
          <p className={styles.value}>{facade ?
            <DictionaryView dictKey="residential_complex_facade" dictItemId={facade}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Форма договора</p>
          <p className={styles.value}>{contractType ?
            <DictionaryView dictKey="residential_complex_contract_type" dictItemId={contractType}/> : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Территория</p>
          <p className={styles.value}>{territory ?
            <DictionaryView dictKey="residential_complex_territory" dictItemId={territory}/> : '-'}</p>
        </div>
      </div>
    </section>
  );
};
