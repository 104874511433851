import React, {FC} from 'react';
import { ApartmentDto } from "../../../../services";
import { DictionaryView } from "../../../../components/atoms";
import styles from './ApartmentInfo.module.scss';

interface ApartmentInfoProps {
  apartmentData: ApartmentDto;
}

export const ApartmentInfo: FC<ApartmentInfoProps> = ({ apartmentData }) => {
  // Дата подготовки презентации
  const presentationDate = new Date().toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  const totalArea = apartmentData?.square;
  const livingArea = apartmentData?.square_living;
  const kitchenArea = apartmentData?.square_kitchen;
  const bathroomArea = apartmentData?.square_bathroom;
  const roomType = apartmentData?.catalogs?.rooms;
  const floor = apartmentData?.floor;
  const building = apartmentData?.building_title;
  const apartmentType = apartmentData?.catalogs?.apartment_type_room;


  return (
    <section className={styles.apartmentInfo}>
      <p className={styles.note}>Презентация подготовлена на {presentationDate}</p>
      <div className={styles.details}>
        <div className={styles.item}>
          <p className={styles.label}>Площадь общая</p>
          <p className={styles.value}>{totalArea ? `${totalArea} м²` : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Площадь жилая</p>
          <p className={styles.value}>{livingArea ? `${livingArea} м²` : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Площадь кухни</p>
          <p className={styles.value}>{kitchenArea ? `${kitchenArea} м²` : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Площадь санузла</p>
          <p className={styles.value}>{bathroomArea ? `${bathroomArea} м²` : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Количество комнат</p>
          <div className={styles.value}>{roomType ? <DictionaryView dictKey="rooms" dictItemId={roomType} /> : '-' }</div>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Этаж</p>
          <p className={styles.value}>{floor ? floor : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Корпус</p>
          <p className={styles.value}>{building ? building : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Тип помещения</p>
          <div className={styles.value}>{apartmentType ? <DictionaryView dictKey="apartment_type_room" dictItemId={apartmentType} /> : '-'}</div>
        </div>
      </div>
    </section>
  );
};
