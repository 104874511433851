import { useModal } from 'react-modal-hook';
import React, { ComponentProps, useMemo, useRef } from 'react';
import ReactModal from 'react-modal';
import { useMediaQuery } from 'beautiful-react-hooks';
import { MobilePopup, Text } from 'components/atoms';
import { Icon } from 'components/atoms/Icon';
import arrowLeft from 'assets/arrowLeftDark.svg';
import styles from '../PdfSettingsPopup.module.scss';
import { PdfSettingsPopup } from '../PdfSettingsPopup';
import { ApartmentDto, Complex } from "../../../../../services";

type PopupWrapperProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const usePdfSettingsPopup = () => {
  const isLarge = useMediaQuery('(min-width: 1024px)');

  // Переименовываем рефы, чтобы было ясно, что это рефы
  const complexDataRef = useRef<Complex | null>(null);
  const apartmentRef = useRef<ApartmentDto | null>(null);

  const PopupWrapper = useMemo(() => {
    if (isLarge) {
      return (props: ComponentProps<typeof ReactModal>) => (
        <ReactModal
          {...props}
          isOpen
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          ariaHideApp={false}
          overlayClassName={styles.modalOverlay}
          className={styles.modal}
        />
      );
    }
    return (
      props: Pick<ComponentProps<typeof MobilePopup>, 'children' | 'isOpen' | 'onClose'>
    ) => (
      <MobilePopup
        IconBack={<Icon src={arrowLeft} />}
        headerChildren={
          <Text align="center" size="L" weight="700">
            Настройка PDF
          </Text>
        }
        {...props}
      />
    );
  }, [isLarge]);

  // useModal без аргументов, данные берутся из ref
  const [showModal, hideModal] = useModal(() => {
    const currentComplex = complexDataRef.current;
    const currentApartment = apartmentRef.current;

    return (
      <PopupWrapper
        isOpen
        onClose={hideModal}
        onRequestClose={hideModal}
      >
        {currentComplex && (
          <PdfSettingsPopup
            onClose={hideModal}
            complexData={currentComplex}
            apartmentData={currentApartment}
          />
        )}
      </PopupWrapper>
    );
  }, [PopupWrapper]);

  const openPdfSettingsPopup = (complexData: Complex | null, apartment: ApartmentDto | null) => {
    complexDataRef.current = complexData;
    apartmentRef.current = apartment;
    showModal();
  };

  return {
    showPdfSettingsPopup: openPdfSettingsPopup,
    hidePdfSettingsPopup: hideModal,
  };
};
